<!-- 导入题目 -->
<template>
  <div>
    <el-card style="position: relative">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-divider content-position="center">{{ title }}</el-divider>
        <el-form-item label="选择年级：" prop="gradeValue">
          <el-select v-model="ruleForm.gradeValue" placeholder="请选择年级" size="mini">
            <el-option
              v-for="item in gradeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择学科：" prop="regionSubject">
          <el-select v-model="ruleForm.regionSubject" placeholder="请选择学科" size="mini">
            <el-option
              v-for="item in subjectIdOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="题目类型：" prop="typeSubject">
          <el-select v-model="ruleForm.typeSubject" placeholder="请选择类型" size="mini">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="题目名称：" prop="name">
          <el-input v-model="ruleForm.name" size="mini" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="题目备注：">
          <el-input v-model="ruleForm.remarks" size="mini" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="题目内容：" prop="IffroMaddEditor">
          <mceEditor v-model="ruleForm.IffroMaddEditor" ref="froMaddEditor" :height="400" />
        </el-form-item>
        <el-form-item label="题目解析：">
          <mceEditor v-model="analysisEditor" ref="analysisEditor" :height="400" />
        </el-form-item>
        <el-tag
          :key="tag"
          v-for="tag in dynamicTags"
          closable
          :disable-transitions="false"
          @close="tagClose(tag)"
        >{{ tag }}</el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        ></el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 关键字</el-button>
      </el-form>
      <div class="foot_button">
        <el-button @click="$emit('go_back', 0)" size="small">取消</el-button>
        <el-button
          type="primary"
          @click="new_subject('ruleForm')"
          size="small"
        >{{ type == 0 ? "添加" : "编辑" }}</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import mceEditor from "../../materialGather/k12SetQuestion/mceEditor.vue";
import { resourceAdd, resourceUpdate } from "../../../api/api";
export default {
  props: {
    edit_obj: {
      type: Object,
    },
  },
  data () {
    return {
      ruleForm: {
        name: "",
        remarks: "",
        regionSubject: "",
        gradeValue: "",
        topic_value: "", //题目内容
        analysis: "", //题目解析
        IffroMaddEditor: "", //富文本输入内容
        typeSubject: '',//题目类型
      },
      rules: {
        gradeValue: [
          { required: true, message: "请选择年级", trigger: "change" },
        ],
        regionSubject: [
          { required: true, message: "请选择学科", trigger: "change" },
        ],
        typeSubject: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入题目名称", trigger: "blur" }],
        remarks: [
          { required: true, message: "请输入题目备注", trigger: "blur" },
        ],
        IffroMaddEditor: [
          { required: true, message: "请输入题目内容", trigger: "blur" },
        ],
      },
      typeOptions: this.parameter.variantSubjectData(),
      gradeOptions: this.parameter.grade(),
      gradeValue: "",
      subjectIdOptions: this.parameter.subject(),
      subjectValue: "",
      innerVisible: false, //富文本弹框
      analysisEditor: "", //解析富文本
      dynamicTags: [], //关键字数组
      inputVisible: false, //关键字输入框
      inputValue: "", //关键字输入框输入内容
      subject_id: "", //题目id
      type: 0, //0 添加题目  1 编辑题目
      title: "添加题目", //标题
    };
  },
  watch: {
    edit_obj (val, old) {
      if (val !== old) {
        this.ruleForm.gradeValue = val.gradeId;
        this.ruleForm.regionSubject = val.subjectId;
        this.ruleForm.typeSubject = val.type;
        this.ruleForm.name = val.name;
        this.ruleForm.remarks = val.remark;
        this.ruleForm.IffroMaddEditor = val.topic;
        this.analysisEditor = val.analysis;
        if (val.keyword !== null) {
          this.dynamicTags = val.keyword.split(",");
        }
        if (val.keyword == 'NULL') {
          this.dynamicTags = []
        }
        this.type = 1;
        this.title = "编辑题目";
        this.subject_id = val.id;
      }
    }
  },
  mounted () {
    this.typeOptions.push({
      value: 6,
      label: "解答题",
    })
  },
  components: {
    mceEditor,
  },

  methods: {
    // 删除关键字
    tagClose (tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    handleInputConfirm () {
      let inputValue = this.inputValue;
      if (inputValue != "" && !/^[ ]+$/.test(inputValue)) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    // 输入关键字
    showInput () {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    // 确定新增
    new_subject (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.IffroMaddEditor !== "") {
            if (this.type == 0) {
              let param = {
                name: this.ruleForm.name,
                gradeId: this.ruleForm.gradeValue,
                subjectId: this.ruleForm.regionSubject,
                remark: this.ruleForm.remarks,
                topic: this.ruleForm.IffroMaddEditor,
                keyword: this.dynamicTags.length == 0 ? 'NULL' : this.dynamicTags.join(","), //关键字
                analysis: this.analysisEditor, //解析
                type: this.ruleForm.typeSubject
              };
              resourceAdd(param).then((res) => {
                if (res.code == 0) {
                  this.ruleForm.name = "";
                  this.ruleForm.remarks = "";
                  this.ruleForm.IffroMaddEditor = "";
                  this.analysisEditor = "";
                  this.dynamicTags = [];
                  this.$message.success("添加成功!您可以继续添加!");
                } else {
                  this.$message.error(res.message);
                }
              });
            } else if (this.type == 1) {
              let param = {
                id: this.subject_id,
                name: this.ruleForm.name,
                gradeId: this.ruleForm.gradeValue,
                subjectId: this.ruleForm.regionSubject,
                remark: this.ruleForm.remarks,
                topic: this.ruleForm.IffroMaddEditor,
                keyword: this.dynamicTags.length == 0 ? 'NULL' : this.dynamicTags.join(","), //关键字
                analysis: this.analysisEditor, //解析
                type: this.ruleForm.typeSubject
              };
              resourceUpdate(param).then((res) => {
                if (res.code == 0) {
                  this.$message({
                    message: "编辑成功！即将返回...",
                    type: "success",
                  });
                  setTimeout(() => {
                    this.$emit("go_back", 0);
                  }, 1000);
                } else {
                  this.$message.error(res.message);
                }
              });
            }
          } else {
            this.$message({
              message: "请添加题目",
              type: "warning",
            });
          }
        } else {
          return false;
        }
      });
    },
    // 二次取消
    handleClose (done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
  },
};
</script>
<style lang='less' scoped>
@import '../style/index.less';
.el-tag {
  margin: 10px 0 10px 10px;
}
.button-new-tag {
  margin: 10px 0 10px 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin: 10px 0 10px 10px;
  vertical-align: bottom;
}
.foot_button {
  display: inline-block;
  position: relative;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%);
}
</style>
