<!-- 题库 -->
<template>
  <div class="card_box_div">
    <el-scrollbar style="height: 100%" v-if="show_subject_list == 0">
      <el-card class="card_box">
        <el-select v-model="gradeValue" placeholder="请选择年级" size="mini">
          <el-option
            v-for="item in gradeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select v-model="subjectValue" placeholder="请选择学科" size="mini" style="margin-left: 15px">
          <el-option
            v-for="item in subjectIdOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button
          type="primary"
          style="margin-bottom: 20px; margin-left: 20px"
          size="mini"
          @click="nav_operation(0)"
        >查询</el-button>
        <el-button type="primary" size="mini" @click="nav_operation(1)">重置</el-button>
        <el-button
          type="primary"
          style="margin-bottom: 20px"
          size="mini"
          class="btn_Import"
          @click="nav_operation(2)"
        >添加题目</el-button>
        <el-table :data="tableData" border style="width: 100%" v-loading="loading">
          <el-table-column prop="gradeName" label="年级"></el-table-column>
          <el-table-column prop="subjectName" label="学科"></el-table-column>
          <el-table-column prop="name" label="题目名称"></el-table-column>
          <el-table-column prop="remark" label="题目备注"></el-table-column>
          <el-table-column prop="keyword" label="关键字"></el-table-column>
          <el-table-column prop="createUserName" label="创建人"></el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
          <el-table-column prop="changeUserName" label="修改人"></el-table-column>
          <el-table-column prop="changeTime" label="修改时间"></el-table-column>
          <el-table-column width="160" label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                class="el-icon-edit"
                @click="operation('edit', scope.row)"
                size="small"
              ></el-button>
              <el-button
                type="danger"
                class="el-icon-delete"
                @click="operation('delete', scope.row)"
                size="small"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="page.pageSize"
          :current-page.sync="page.pageNum"
          layout="total, prev, pager, next"
          :total="page.total"
          background
          style="margin-top: 10px"
        ></el-pagination>
      </el-card>
    </el-scrollbar>
    <importQuestion v-if="show_subject_list == 1" @go_back="go_back" :edit_obj="edit_obj"></importQuestion>
  </div>
</template>

<script>
import { client } from "../../components/AliOss";
import importQuestion from "./components/importQuestion.vue";
import { resourceList, resourceRemove, resourceDetail, resourceOne } from "../../api/api";
export default {
  data () {
    return {
      page: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
      fileList: [],
      tableData: [],
      gradeValue: "",
      gradeOptions: this.parameter.grade(),
      subjectIdOptions: this.parameter.subject(),
      edit_obj: {}, //编辑的对象
      subjectValue: "",
      show_new_subject: false,
      judge_url: "",
      show_subject_list: 0, //0 展示列表  1 展示导入页面
      subject_id: "",
      show_load: false,
      innerVisible: false, //内层弹框
      IffroMaddEditor: "", //富文本内容
      loading: false,
    };
  },

  components: {
    importQuestion,
  },
  watch: {},
  mounted () {
    this.subject_list();
  },
  methods: {
    go_back (val) {
      this.subject_list();
      this.show_subject_list = 0;
    },
    // 上传控制文件大小
    beforeAvatarUpload (file) {
      const isdocx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/msword";
      const isLt5M = file.size / 1024 / 1024 < 5;
      const isLt0K = file.size / 1024 / 1024 > 0;

      if (!isdocx) {
        this.$message.error("上传文件只能是word文档！");
      }
      if (!isLt5M || file.size == 0) {
        this.$message.error("上传word文档大小不能为空且不能超过 5MB!");
      }
      return isdocx && isLt5M && isLt0K;
    },

    // 导航操作组
    nav_operation (type) {
      // 0 查询  1 重置  2 导入题目
      if (type == 0) {
        this.page.pageNum = 1;

        this.subject_list();
      } else if (type == 1) {
        this.gradeValue = "";
        this.subjectValue = "";
        this.page.pageNum = 1;
        this.subject_list();
      } else if (type == 2) {
        this.show_subject_list = 1;
        this.ruleForm = {
          name: "",
          remarks: "",
          regionSubject: "",
          gradeValue: "",
        };
        this.show_new_subject = true;
      }
    },
    // 换页
    handleCurrentChange (val) {
      this.page.pageNum = val;
      this.subject_list();
    },

    // 题目列表
    subject_list () {
      this.loading = true
      this.tableData = [];
      let param = {
        pageSize: this.page.pageSize,
        pageNum: this.page.pageNum,
        gradeId: this.gradeValue,
        subjectId: this.subjectValue,
      };
      resourceList(param).then((res) => {
        this.loading = false
        if (res.code == 0) {
          if (res.data !== null) {
            if (res.data.list.length > 0) {
              res.data.list.map((item) => {
                this.subjectIdOptions.map((itm1) => {
                  if (itm1.value == item.subjectId) {
                    item.subjectName = itm1.label;
                  }
                });
                this.gradeOptions.map((itm1) => {
                  if (itm1.value == item.gradeId) {
                    item.gradeName = itm1.label;
                  }
                });
                item.createTime = item.createTime
                  ? this.duration.getDateStr(item.createTime)
                  : "--";
                item.changeTime = item.changeTime
                  ? this.duration.getDateStr(item.changeTime)
                  : "--";
                item.changeUserName = item.changeUserName
                  ? item.changeUserName
                  : "暂无修改";
                item.keyword = item.keyword == 'NULL' ? '暂无关键字' : item.keyword
              });
              this.tableData = res.data.list;
              this.page.total = res.data.total;
            }
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 操作题目
    operation (type, val) {
      if (type == "edit") {
        this.show_subject_list = 1;
        this.subject_id = val.id;
        resourceDetail({ id: val.id }).then((res) => {
          console.log(res);
          if (res.code == 0) {
            if (!res.data.keyword) {
              res.data.keyword = null;
            }
            this.edit_obj = res.data;
          } else {
            this.$message.error(res.message);
          }
        });
      } else if (type == "delete") {
        this.$confirm("此操作将永久删除该题目, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            resourceRemove([val.id]).then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                setTimeout(() => {
                  this.subject_list();
                }, 1000);
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    // 删除文件
    handleRemove (file, fileList) {
      this.judge_url = "";
    },
    handlePreview (file) { },
    handleExceed (files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    // 移除文件
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleUpload (param) {
      this.show_load = true;
      let file = param.file;
      let data = param.data;
      let name = file.name;
      let self = this;
      const fileName = `${Date.parse(new Date())}/${name}`; //定义唯一的文件名
      try {
        client()
          .put(fileName, file)
          .then(({ res, url }) => {
            if (res && res.status == 200) {
              let a = url.split("com");
              this.judge_url = a[1];
              this.show_load = false;
            }
          })
          .catch((err) => { });
        let file_data = new FormData();
        file_data.append("file", file);
      } catch (error) {
        self.message.error(error);
      }
    },
  },
};
</script>
<style lang='less' scoped>
@import '../../style/mouse.less';
@import './style/index.less';
/deep/ .el-table {
  .cell {
    white-space: nowrap;
  }
}
</style>
